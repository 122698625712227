import React, { useEffect } from 'react'
export default function PaymentSuccess() {
  useEffect(() => {
    const myTimeout = setTimeout(() => {
      window.close()
    }, 3000);

    return () => {
      clearTimeout(myTimeout);
    }
  }, [])
  return (
    <div className='payment payment_success'>
      <img src='/images/paymentSuccess.svg' />
      <h3>Transaction Successful !!! :)</h3>
    </div>
  )
}

