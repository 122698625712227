import React, { useState, useEffect } from "react";
import config from "../../config/config.json";
import "../PasswordChange/PasswordChange.css";
import updateDoctor from "../../api/Doctor_Api/updateDoctor";
import { FaEye, FaEyeSlash } from 'react-icons/fa';

export default function DoctorPasswordRest() {
    const [isLoading, setisLoading] = useState(true)
    const [Confirmpassword, setConfirmpassword] = useState("");
    const [password, setpassword] = useState("");
    const [msgsuccess, setmsgsuccess] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const handleToggle = () => {
        setShowPassword(!showPassword);
    }
    const handleToggle2 = () => {
        setShowPassword2(!showPassword2);
    }

    function handleSubmit(event) {
        if (password === Confirmpassword) {
            console.log(Confirmpassword);
            const url = new URL(window.location.href);
            let params = new URLSearchParams(url.search)
            let request = {
                doctor_id: params.get("id"),
                doctor_password: password
            }
            console.log("request", request);
            updateDoctor(request)
                .then((data) => {
                    console.log("data", data);
                    if (data.status === true) {
                        setmsgsuccess('Password Changed Successfully');
                        setTimeout(() => {
                            setmsgsuccess("");
                            window.location.reload();
                        }, 2000);
                    } else {
                        document.getElementById("msg").style.color = "red";
                        setmsgsuccess("Something went wrong !");
                        setTimeout(() => {
                            setmsgsuccess("");
                        }, 2000);
                    }
                })
                .catch((err) => {
                    console.error("login error >>>>>>>>>", err);
                });
        }
        else {
            document.getElementById("msg").style.color = "red";
            setmsgsuccess("both passwords should be same !");
        }
        event.preventDefault();
    }

    return (
        <div className="login-page">
            <div className="login-box">
                <div className="illustration-wrapper">
                    <img
                        src="/images/logoimg.png"
                        alt="Login"
                    />
                </div>
                <div className="login">
                    <form
                        name="login-form"
                        onSubmit={(e) => {
                            handleSubmit(e);
                        }}
                    >
                        <p className="form-title">Reset Password</p>
                        <p className="ptag-confirm"> Enter your new password below, we're just being extra safe </p>
                        <input
                            className="input_p"
                            name="password"
                            placeholder="New Password"
                            type={showPassword ? 'text' : 'password'}
                            onChange={(e) => {
                                setpassword(e.target.value);
                            }}
                        />
                        <span class="flex justify-around items-center" onClick={handleToggle}>
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </span>
                        <br></br>
                        <input
                            className="input_p"
                            name="Cpassword"
                            placeholder="Confirm Password"
                            type={showPassword2 ? 'text' : 'password'}
                            onChange={(e) => {
                                setConfirmpassword(e.target.value);
                            }}
                        />
                        <span class="flex justify-around items-center" onClick={handleToggle2}>
                            {showPassword2 ? <FaEyeSlash /> : <FaEye />}
                        </span>
                        <br />
                        <div className="btn-login">
                            <input
                                type="submit"
                                value="Reset Password"
                                className="login-form-button"
                            />
                        </div>

                        <br></br>
                    </form>
                    <p className="msgsuccess" id="msg">
                        {msgsuccess}
                    </p>

                </div>
            </div>
        </div>
    );
}
