// let format = require("date-fns").format()

export function convertTofixed(x) {
    return Number.parseFloat(x).toFixed(2);
}


export function generateOrderid() {
    let prefix = "WFT"
    let date = Date.now()
    // format(new Date(),"hhMMss")
    let random = Math.round(Math.random() * 100000)
    return prefix + date + random
}