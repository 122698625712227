let jws = require("jws");
let config = require("./config/config.json")



module.exports.encryptPaymentRequest = (reqStr) => {
    console.log("🚀 ~ reqStr:", reqStr)
    const signature = jws.sign({
        header: { alg: 'HS256', "clientid": config.payment.client_id },
        payload: reqStr,
        secret: config.payment.secret_key
    })
    console.log({
        header: { alg: 'HS256', "clientid": config.payment.client_id },
        payload: reqStr,
        secret: config.payment.secret_key
    })

    return signature
}


module.exports.decryptPaymentResponse = (respStr) => {

    let response = jws.decode(respStr, { json: true })

    return response
}

// console.log(decryptPaymentResponse("eyJhbGciOiJIUzI1NiIsImNsaWVudGlkIjoidWF0d29yayIsImtpZCI6IkhNQUMifQ.eyJvYmplY3RpZCI6Im9yZGVyIiwib3JkZXJpZCI6IldGVDE2ODA5NTMxNDE2NzI4MTY4NyIsImJkb3JkZXJpZCI6Ik9BUkMxOVhUVjlQR0FIIiwibWVyY2lkIjoiVUFUV09SSyIsIm9yZGVyX2RhdGUiOiIyMDIzLTA0LTA4VDA0OjU1OjQxKzA1OjMwIiwiYW1vdW50IjoiMzAwMC4wMCIsImN1cnJlbmN5IjoiMzU2IiwicnUiOiJodHRwOi8vd29ya2ZpdHQuaW4vcGF5bWVudCIsImFkZGl0aW9uYWxfaW5mbyI6eyJhZGRpdGlvbmFsX2luZm8xIjoiMzQiLCJhZGRpdGlvbmFsX2luZm8yIjoiTkEiLCJhZGRpdGlvbmFsX2luZm8zIjoiTkEiLCJhZGRpdGlvbmFsX2luZm80IjoiTkEiLCJhZGRpdGlvbmFsX2luZm81IjoiTkEiLCJhZGRpdGlvbmFsX2luZm82IjoiTkEiLCJhZGRpdGlvbmFsX2luZm83IjoiTkEiLCJhZGRpdGlvbmFsX2luZm84IjoiTkEiLCJhZGRpdGlvbmFsX2luZm85IjoiTkEiLCJhZGRpdGlvbmFsX2luZm8xMCI6Ik5BIn0sIml0ZW1jb2RlIjoiRElSRUNUIiwiY3JlYXRlZG9uIjoiMjAyMy0wNC0wOFQxNjo1NTo0MSswNTozMCIsIm5leHRfc3RlcCI6InJlZGlyZWN0IiwibGlua3MiOlt7ImhyZWYiOiJodHRwczovL3d3dy5iaWxsZGVzay5jb20vcGdpL3ZlMV8yL29yZGVycy9XRlQxNjgwOTUzMTQxNjcyODE2ODciLCJyZWwiOiJzZWxmIiwibWV0aG9kIjoiR0VUIn0seyJocmVmIjoiaHR0cHM6Ly9wZ3VhdHdlYi5iaWxsZGVzay5pby9wZ3R4bnNpbXVsYXRvci92MV8yL3RyYW5zYWN0aW9ucy9vcmRlcmZvcm0iLCJyZWwiOiJyZWRpcmVjdCIsIm1ldGhvZCI6IlBPU1QiLCJwYXJhbWV0ZXJzIjp7Im1lcmNpZCI6IlVBVFdPUksiLCJiZG9yZGVyaWQiOiJPQVJDMTlYVFY5UEdBSCJ9LCJ2YWxpZF9kYXRlIjoiMjAyMy0wNC0wOFQxNzoyNTo0MSswNTozMCIsImhlYWRlcnMiOnsiYXV0aG9yaXphdGlvbiI6Ik9Ub2tlbiBDRDY1MUM5OTlCMEI5OTBEOEREREVERDlFQ0UzQTZBRjRGMzk1QTA0Qjg5RjY5REI2RkU4NTNFQ0E5M0QxNzcxODlCMzNDMjQ3REUxRTJFODhGMzM0MjIwMDE0NEEwQTlGMUMxQjU0NjNCM0JGMzc5NDM4MTNCODU0QUZBRUIyN0JENzc2RDYzQzZFRDNBNjcxNDJDRUU3QTNDOUNCN0RGQ0RFMzM5MEQ2RURERUE4MURFM0JBRDg1MTI4MEMyNDMyMTE1NjYyOUZFQjI4MEY4QUFCRjBBMEU1NTE1QjY2ODhFRTU5OEZCMEVFRTNGNjFBQUE3NEE5QjU1OTc0REU3QkEyQkE1Q0RDNDVFRjRBRTJCN0YwMkI3QzU0NDUyQ0Q2NDYxM0E2RTc5RTg2NzA0LjQxNDU1MzVGNTU0MTU0MzEifX1dLCJzdGF0dXMiOiJBQ1RJVkUifQ.fm852m7JZAbFfB4ii5ACjxAsvZhReZuBMH414XEnvKA"))
