import axios from "axios";
import config from "../../config/config.json"
import { encryptPaymentRequest } from "../../encrypt_decrypt";

export default async function createOrder(req) {
    let header = {
        'Content-Type': "application/jose",
        "BD-timestamp": Date.now(),
        "accept": "application/jose",
        "BD-traceid": req.orderid
    }
    // let headerEncrypt=await encryptPaymentRequest(JSON.stringify(header),config.payment.secret_key,config.payment.client_id)
    let encryptedBody = await encryptPaymentRequest(JSON.stringify(req))

    let Body = {
        header,
        encryptedBody
    }
    try {
        let resp = await axios.post(`${config.backend_url}/createOrder`, Body);
        // let resp = await axios.post(config.payment.create_order_api,encryptedBody,{ headers: header })
        console.log("🚀 ~ file: createOrderApi.js:21 ~ createOrder ~ resp:", resp)
        return resp.data;
    } catch (err) {
        return false
    }
}