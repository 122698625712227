import React,{useState,useEffect} from 'react'
import config_url from "../../config/config.json"



export default function Payment() {
    const [showSpinner, setshowSpinner] = useState(false)

    useEffect(() => {
        loadXMLDoc()
    }, [])

    const responseHandler = function (txn) {
        var responseXHR = new XMLHttpRequest();
        responseXHR.onreadystatechange = function () {
            if (responseXHR.readyState == XMLHttpRequest.DONE) { // XMLHttpRequest.DONE == 4
                if (responseXHR.status == 200) {
                    let jsonStr = responseXHR.responseText;
                    // console.log(jsonStr);
                    let jsonObj = JSON.parse(jsonStr);
                    console.log("🚀 ~ file: Payment.js:21 ~ responseHandler ~ jsonObj:", jsonObj)
                    // console.log(jsonObj.orderid);
                    let htm = "";
                    htm += "<div>Transaction Id: " + jsonObj.transactionid + " </div>";
                    htm += "<div>Auth Status: " + jsonObj.auth_status + " </div>";
                    htm += "<div>Transaction Date: " + jsonObj.transaction_date + " </div>";
                    htm += "<div>Payment Method Type: " + jsonObj.payment_method_type + "<div>";
                    htm += "<div>Amount: " + jsonObj.charge_amount + " </div>";
                    htm += "<div>Bank Ref No.: " + jsonObj.bank_ref_no + " </div>";
                    htm += "<div>Error code: " + jsonObj.transaction_error_code + " </div>";
                    htm += "<div>Error desc: " + jsonObj.transaction_error_desc + " </div>";
                    document.getElementById("result").innerHTML = htm;
                    setshowSpinner(false);
                }
                else if (responseXHR.status == 400) {
                    //alert('There was an error 400');
                } 
                else {
                    //alert('something else other than 200 was returned');
                }
            }
        };
        // console.log(txn);
        if (txn.txnResponse) {
            // https://pguatweb.billdesk.io/pgtxnsimulator/v1_2/transactions/orderform
            responseXHR.open("POST", "https://pguatweb.billdesk.io/pgtxnsimulator/v1_2/txnresponse", true);
            responseXHR.send(txn.txnResponse.transaction_response);
        } else {
            setshowSpinner(false);
        }
    }

    

    function loadXMLDoc() {
        let url=new URL(window.location.href)
        var flow_config = {
            // merchantId: "BDMONITOR",
            merchantId: config_url.payment.merchant_id,
            bdOrderId: new URLSearchParams(url.search).get("bdOrderId"),
            authToken: new URLSearchParams(url.search).get("authToken"),
            childWindow: false,
            returnUrl: config_url.backend_url+"/payment_handler"
        };
        
        console.log("🚀 ~ file: Payment.js:61 ~ loadXMLDoc ~ flow_config:")
    
        let config = {
            responseHandler: responseHandler,
            merchantLogo: "",
            flowConfig: flow_config,
            flowType: "payments"
        };
        setshowSpinner(true)
        document.getElementById("result").innerHTML = "";
        // var xmlhttp = new XMLHttpRequest();
        // var jsonObj = "";
        window.loadBillDeskSdk(config);
    }

    return (
        <div class="container">
            {showSpinner ?
                <div id="spinner" class="mt-3 text-center">
                    <div class="spinner-border" role="status" style="width: 5rem; height: 5rem;">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                : null
            }
            <div id="result" class="jumbotron mt-3">
            </div>
        </div>
    )
}
