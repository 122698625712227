import React, {useEffect} from 'react'

function PaymentFail() {
  useEffect(() => {
    const myTimeout = setTimeout(() => {
window.close()  
  }, 3000);
      
    return () => {
      clearTimeout(myTimeout);
    }
  }, [])
  
  return (
    <div className='payment payment_fail'>
      <img src='/images/paymentFail.svg'/>
      <h3>Oop's Transaction Failed !!! :(</h3>
    </div>
  )
}

export default PaymentFail