import React,{useEffect} from 'react'
import { generateOrderid, convertTofixed } from "../../common"
import { format } from "date-fns"
import config from "../../config/config.json"
import createOrder from "../../api/Payment_Api/createOrder";
import { decryptPaymentResponse } from "../../encrypt_decrypt"

export default function PrePayment() {
    useEffect(() => {
        async function initialFetch(){
            let data = JSON.parse(new URLSearchParams(window.location.search).get("data"))
            let doctorId = new URLSearchParams(window.location.search).get("id")
            // let data = {"doctor_name":"test","doctor_address":"test","registration_number":"test","doctor_username":"test9876543","doctor_password":"Test@123","doctor_confirm_password":"Test@123","doctor_email":"akshay@gmail.com","doctor_mobile":7208470446,"consultation_charge":234,"treatment1_charge":234,"treatment2_charge":34,"treatment3_charge":23,"doctor_degree":"qwer","specialisation":"qwer","doctor_sign":{"0":{}},"doctor_logo":{"0":{}},"subscription":"6-month-3000"}
            // let doctorId = 51
            let subscription_Arr=data.subscription.split("-")
            let subscription_charges=subscription_Arr[3]
            let device={
                "init_channel":"internet",
                "ip":"215.206.112.235",
                "user_agent":"Mozilla/5.0 (Windows NT 10.0; WOW64; rv:51.0)Gecko/20100101 Firefox/51.0",
                }
            let pgRequest= {
                "mercid":config.payment.merchant_id,
                "orderid":generateOrderid(),
                "amount":convertTofixed(subscription_charges),
                "order_date":format(new Date(),"yyyy-MM-dd") + "T" + format(new Date(),"HH:mm:ss")+"+05:30",
                "currency":config.payment.currency_code,
                "itemcode":"DIRECT",
                "ru":config.backend_url+"/payment_handler",
                "additional_info":{
                    "additional_info1":doctorId + "",
                },
                "device":device
            } 
            console.log("🚀 ~ initialFetch ~ pgRequest:", pgRequest, JSON.stringify(pgRequest))
            
            let createOrderResp =  await createOrder(pgRequest)
            createOrderResp = decryptPaymentResponse(createOrderResp)
            console.log("🚀 ~ file: PrePayment.js:37 ~ initialFetch ~ createOrderResp:", JSON.stringify(createOrderResp))
            // let createOrderResp = {
            //     "objectid": "order",
            //     "orderid": "WFT168095314167281687",
            //     "bdorderid": "OARC19XTV9PGAH",
            //     "mercid": "UATWORK",
            //     "order_date": "2023-04-08T04:55:41+05:30",
            //     "amount": "3000.00",
            //     "currency": "356",
            //     "ru": "http://workfitt.in/payment",
            //     "additional_info": {
            //       "additional_info1": "34",
            //       "additional_info2": "NA",
            //       "additional_info3": "NA",
            //       "additional_info4": "NA",
            //       "additional_info5": "NA",
            //       "additional_info6": "NA",
            //       "additional_info7": "NA",
            //       "additional_info8": "NA",
            //       "additional_info9": "NA",
            //       "additional_info10": "NA"
            //     },
            //     "itemcode": "DIRECT",
            //     "createdon": "2023-04-08T16:55:41+05:30",
            //     "next_step": "redirect",
            //     "links": [
            //       {
            //         "href": "https://www.billdesk.com/pgi/ve1_2/orders/WFT168095314167281687",
            //         "rel": "self",
            //         "method": "GET"
            //       },
            //       {
            //         "href": "https://pguatweb.billdesk.io/pgtxnsimulator/v1_2/transactions/orderform",
            //         "rel": "redirect",
            //         "method": "POST",
            //         "parameters": {
            //           "mercid": "UATWORK",
            //           "bdorderid": "OARC19XTV9PGAH"
            //         },
            //         "valid_date": "2023-04-08T17:25:41+05:30",
            //         "headers": {
            //           "authorization": "OToken CD651C999B0B990D8DDDEDD9ECE3A6AF4F395A04B89F69DB6FE853ECA93D177189B33C247DE1E2E88F3342200144A0A9F1C1B5463B3BF37943813B854AFAEB27BD776D63C6ED3A67142CEE7A3C9CB7DFCDE3390D6EDDEA81DE3BAD851280C24321156629FEB280F8AABF0A0E5515B6688EE598FB0EEE3F61AAA74A9B55974DE7BA2BA5CDC45EF4AE2B7F02B7C54452CD64613A6E79E86704.4145535F55415431"
            //         }
            //       }
            //     ],
            //     "status": "ACTIVE"
            //   }
              window.location.href = `${config.Base_url}/payment?bdOrderId=${createOrderResp.payload.links[1].parameters.bdorderid}&authToken=${createOrderResp.payload.links[1].headers.authorization}`
            // console.log("tetsing heree 1111111111111",createOrderResp);
        }

    initialFetch()
    }, [])
    
    return (
    <div>PrePayment</div>
    )
}
